.Title {
  color: #ffffff;
  font-family: Montserrat;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 117.9%; /* 70.74px */
  width: 100%;
  max-width: 1152px;
}
@media (max-width: 428px) {
  .Title {
    font-size: 36px;
    line-height: 110%;
  }
}
.Calendar {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
