.Button {
  display: inline-flex;
  padding: 9px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #002f67;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  border: none;
  box-shadow: 0px 0px 0px 0px rgba(134, 134, 134, 0);
  transition: all 0.3s ease-in-out;
}
.Button:hover {
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  background: #094c9e;
  box-shadow: 0px 0px 6px 4px rgba(134, 134, 134, 0.22);
}
.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.ButtonLink {
  color: #002f67;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
}
