.Title {
  color: #393939;
  font-family: Montserrat;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 117.9%; /* 70.74px */
  width: 100%;
  max-width: 1152px;
}
@media (max-width: 428px) {
  .Title {
    font-size: 36px;
    line-height: 110%;
  }
}
.Calendar {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 50px;
}
.CardWrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 55px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
  margin-bottom: 50px;
}
@media (max-width: 428px) {
  .CardWrapper {
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.calendarImage {
  padding-top: 40px;
  width: 100%;
  max-width: 1200px;
}
