.filters {
  padding-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
}

.resetButton {
  color: #ffffff;
  border-color: #ffffff;
  background-color: #00aeef;
  transition: all 0.25s ease-in-out;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  text-decoration: none;
  border: 3px solid #ffffff;
  transition: all 0.25s ease-in-out;
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  box-sizing: border-box;
  padding: 6px 12px;
}
.resetButton:hover {
  border: 3px solid #ffffff;
  transition: all 0.25s ease-in-out;
  background-color: #ed1e24;
  cursor: pointer;
}
