.Navigation_activeLink {
  display: inline-flex;
  padding: 12px 25px 12px 26px;
  justify-content: center;
  align-items: center;
  /* Дополнительные стили для активных элементов */
  background-color: #007bff; /* Пример цвета фона */
  color: #fff; /* Пример цвета текста */
}
.active {
  display: inline-flex;
  padding: 12px 25px 12px 26px;
  justify-content: center;
  align-items: center;
  /* Дополнительные стили для активных элементов */
  background-color: #007bff; /* Пример цвета фона */
  color: #fff; /* Пример цвета текста */
}
.Navigation {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: flex-start;
  list-style: none;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-inline-start: 0;
}
@media (max-width: 428px) {
  .Navigation {
    flex-direction: column;
    gap: 10px;
  }
}
.Navigation li {
  height: 40px;
}
.Navigation li a {
  color: #ffffff;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  text-decoration: none;
  border: 3px solid #ffffff;
  transition: all 0.25s ease-in-out;
  background-color: #ed1e24;
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  box-sizing: border-box;
  padding: 6px 12px;
}
.Navigation li a:hover {
  color: #ffffff;
  border-color: #ffffff;
  background-color: #00aeef;
  transition: all 0.25s ease-in-out;
}
