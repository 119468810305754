@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "primereact/resources/themes/lara-light-indigo/theme.css";
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #002f67;
  background: linear-gradient(#002f67, #00a9ee);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
ul {
  padding-inline-start: 0px !important;
}
#root {
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 30px;
}
.fs9 .p-inputtext {
  font-size: 9px !important;
}
