.Card {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 12px 0px rgba(134, 134, 134, 0.22);
  width: 100%;
  max-width: 392px;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
}

.CardImage img {
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  max-width: 392px;
  cursor: pointer;
}
.CardInnerWrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  padding: 15px;
  flex: 1;
}
.HelperWrapper {
}
.HelperWrapper2 {
}
.CardTime {
  color: #00aeef;
  font-family: Montserrat;
  font-size: 14px;
  line-height: 30px;
  font-style: normal;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 7px;
}
.CardLocation {
  color: #a8a8a8;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 7px;
}
.CardTitle {
  color: #393939;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 10px;
  cursor: pointer;
}
.CardDescription {
  color: #636363;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
  padding-top: 10px;
  padding-bottom: 10px;
}
.CardButtonsWrapper {
  display: flex;
  margin-top: auto;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
  gap: 15px;
}
.ButtonLink {
  color: #002f67;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
}
.Button {
  display: inline-flex;
  padding: 9px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #002f67;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  border: none;
  box-shadow: 0px 0px 0px 0px rgba(134, 134, 134, 0);
  transition: all 0.3s ease-in-out;
}
.Button:hover {
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  background: #094c9e;
  box-shadow: 0px 0px 6px 4px rgba(134, 134, 134, 0.22);
}
.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
